.logo {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  text-align: center;
}

.text {
  padding: 0 55px;
  position: absolute;
  top: 240px;
  left: 0;
  right: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #fff;
}

.buttons {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 60px;
}

.tin_id {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 4px;
  background: #ffdd2d !important;
  border-radius: 50% !important;
}

.tin_id:hover {
  background: #fcc521;
}

.tin_id_icon {
  width: 100%;
  height: 100%;
}

.divider {
  width: 100%;
  color: #bbb;
}

.divider::before,
.divider::after {
  border-color: #bbb !important;
}

.login {
  width: 100%;
  height: 44px;
  background: linear-gradient(302.01deg, #fab215 -14.62%, #fab215 113.75%) !important;
  border-radius: 12px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #fff !important;
  text-transform: none !important;
}
